.hover-modal-container {
  position: relative;
  display: inline-block;
}

.hover-modal-image {
  height: 22px;
  width: 22px;
}

.modalimg {
  position: fixed;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 8px;
  border: 1px solid #ccc;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalimg img {
  max-width: 100%;
  max-height: 80vh;
}

.modalimg button {
  margin-top: 10px;
}
