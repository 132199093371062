  .loader-container {
    display: flex;
    justify-content: center; /* Horizontal center */
    align-items: center; /* Vertical center */
    height: 82vh; /* Adjust this according to your layout */
  }
  .logo-loader-container {
    position: relative;
    width: 90px; /* Adjust size as needed */
    height: 92px; /* Adjust size as needed */
  }
  
  .logo-loader {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
  }
  
  .loader {
    position: absolute;
    top: 2px;
    left: 0px;
    width: calc(100%);
    height: calc(100%);
    border: 5px solid #ccc;
    border-radius: 50%;
    border-top-color: #227c5e;
    animation: spin 1s linear infinite;
  }
  .loader.active {
    border-color: #6cc9bc;
    border-top-color: #0f9e8b;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }